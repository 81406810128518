import ICommand from "@/types/interfaces/i-command";
import FinishUnitCheckTodoResultPayload
    from "@/types/interfaces/command-payloads/finish-unit-check-todo-result-payload";
import esquireClient from "@/modules/esquire-client";
import {AxiosError} from "axios";
import {HttpResource} from "@/types/enums";
import CommandFailedError from "@/types/errors/command-failed-error";
import ErrorData from "@/http/data/error-data";

export default class FinishUnitCheckTodoResultCommand implements ICommand {
    constructor(private payload: FinishUnitCheckTodoResultPayload, private callback: (id: number) => void) {}

    async execute(): Promise<void> {
        const {
            unitCheckTodoResult,
            hasIssues
        } = this.payload;

        const url = `${HttpResource.UnitCheckTodoResults}/${unitCheckTodoResult.id}`;

        const response = await esquireClient.put(url, {
            command: "finish",
            unit_check_id: unitCheckTodoResult.unit_check_id,
            unit_check_todo_blueprint_id: unitCheckTodoResult.unit_check_todo_blueprint_id,
            category: unitCheckTodoResult.category,
            description: unitCheckTodoResult.description,
            has_issues: hasIssues
        }).catch((e: AxiosError) => e.response);

        if (!response || response.status !== 200) {
            const alert = (response?.data as ErrorData).message;

            throw new CommandFailedError(`Finish Unit Check Todo ${unitCheckTodoResult.id} failed.`, alert);
        }

        this.callback(response.data.id as number);
    }
}