import {Mutations} from "vuex-smart-module";
import UnitState from "@/store/modules/unit/unit-state";
import UnitData from "@/http/data/unit-data";
import UnitBlockadeData from "@/http/data/unit-blockade-data";
import _ from "lodash";
import dayjs from "dayjs";

export default class UnitMutations extends Mutations<UnitState> {
    setUnitBlockade(unitBlockade: UnitBlockadeData): void {
        const unit = this.state.units.find(unit => unit.id === unitBlockade.unit_id);

        if (!unit) return;

        unit.blockade = unitBlockade;
    }

    setIsInitialized(value = true): void {
        this.state.isInitialized = value;
    }

    setSelectedUnitId(unitId: string): void {
        this.state.selectedUnitId = unitId;
    }

    setThoroughInspectionWhereUnitId(
        {
            unitId,
            checkedAt,
            checkedBy
        }: {
            unitId: string,
            checkedAt: Date,
            checkedBy: string
        }): void {
        const unit = _.find(this.state.units, unit => unit.id === unitId);

        if (!unit) return;

        unit.last_unit_inspection_date = dayjs(checkedAt).utc(true).toISOString();
        unit.last_unit_inspection_by = checkedBy;
    }

    setUnits(units: UnitData[]): void {
        this.state.units = units;
    }
}