import {Actions} from "vuex-smart-module";
import BuildingState from "@/store/modules/building/building-state";
import BuildingGetters from "@/store/modules/building/building-getters";
import BuildingMutations from "@/store/modules/building/building-mutations";
import esquireClient from "@/modules/esquire-client";
import {AxiosError} from "axios";
import db from "@/modules/vanity-db";
import _ from "lodash";
import BuildingData from "@/http/data/building-data";

export default class BuildingActions extends Actions<BuildingState, BuildingGetters, BuildingMutations, BuildingActions> {
    async attemptRestoreSelectedBuilding(): Promise<void> {
        const building = await db.selected.get("building");

        if (!building) return;

        this.mutations.setSelectedBuildingId(building.id as string);
    }

    async indexBuildings(): Promise<void> {
        const url = "buildings";

        const response = await esquireClient.get(url).catch((e: AxiosError) => e.response);

        if (!response) return;

        if (response.status === 200) {
            const buildings = _.map(response.data, data => {
                const building = new BuildingData(data);

                if ("reception_times" in data) {
                    building.reception_times = data.reception_times;
                }

                return building;
            });

            this.mutations.setBuildings(buildings);
        }
    }

    async initialize(): Promise<void> {
        await this.actions.indexBuildings();

        this.mutations.setIsInitialized(true);
    }

    async rehydrateStore(): Promise<void> {
        await this.actions.attemptRestoreSelectedBuilding();
    }

    async selectBuilding(buildingId: string): Promise<void> {
        await db.selected.put({
            id: buildingId
        }, "building");

        this.mutations.setSelectedBuildingId(buildingId);
    }
}