import HttpData from "@/http/data/http-data";

export interface IUnitBlockadeData {
    id?: number;
    unit_id?: string;
    description?: string;
    from?: string;
    until?: string;
    type?: string;
    created_by?: string;
}

export default class UnitBlockadeData extends HttpData {
    public id: null|number;
    public unit_id: null|string;
    public description: null|string;
    public from: null|string;
    public until: null|string;
    public type: null|string;
    public created_by: null|string;


    constructor(data: IUnitBlockadeData) {
        super();

        this.id = data.id ?? null;
        this.unit_id = data.unit_id ?? null;
        this.description = data.description ?? null;
        this.from = data.from ?? null;
        this.until = data.until ?? null;
        this.type = data.type ?? null;
        this.created_by = data.created_by ?? null;
    }
}