import {Module} from "vuex-smart-module";
import UnitCheckState from "@/store/modules/unit-check/unit-check-state";
import UnitCheckGetters from "@/store/modules/unit-check/unit-check-getters";
import UnitCheckMutations from "@/store/modules/unit-check/unit-check-mutations";
import UnitCheckActions from "@/store/modules/unit-check/unit-check-actions";

export default new Module({
    state: UnitCheckState,
    getters: UnitCheckGetters,
    mutations: UnitCheckMutations,
    actions: UnitCheckActions
});