import Dexie from "dexie";
import Session from "@/http/data/session-data";

interface Selected {
    id: string|number;
}

class VanityDb extends Dexie {
    selected: Dexie.Table<Selected, string>;
    sessions: Dexie.Table<Session, string>;

    constructor() {
        super("vanity_db");

        this.version(1).stores({
            selected: ", id",
            sessions: ", username, full_name, token, *roles, *abilities"
        });

        this.selected = this.table("selected");
        this.sessions = this.table("sessions");
    }
}

export default new VanityDb();