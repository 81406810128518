import {Vue as VueBase, mixins} from "vue-class-component";
import eventBus from "@/modules/event-bus";
import {Emitter} from "mitt";
import dayjs from "dayjs";
import _ from "lodash";
import {ElMessageBox} from "element-plus/packages/message-box/src/message-box.type";

let uuidBase = 0;

class AppMixin extends VueBase {
    $events!: Emitter;
    $message!: ElMessageBox;

    uuid = ++uuidBase;

    beforeCreate(): void {
        this.$events = eventBus;
    }

    capitalize(value: string): string {
        return _.capitalize(value);
    }

    capitalizeAll(value: string): string {
        return _.chain(value)
            .split(" ")
            .map(word => _.capitalize(word))
            .join(" ")
            .value();
    }

    formatCurrency(amount: number|string): string {
        if (typeof amount === "string") {
            amount = Number.parseFloat(amount);
        }

        return amount.toLocaleString("nl-BE", {
            style: "currency",
            currency: "EUR"
        });
    }

    formatDate(date: string): string {
        return dayjs(date).format("DD-MM-YYYY");
    }

    formatDatetime(date:string): string {
        return dayjs(date).format("DD-MM-YYYY hh:mm:ss");
    }
}

export default class Vue extends mixins(AppMixin) {}