import {Module} from "vuex-smart-module";
import JobState from "@/store/modules/job/job-state";
import JobGetters from "@/store/modules/job/job-getters";
import JobMutations from "@/store/modules/job/job-mutations";
import JobActions from "@/store/modules/job/job-actions";

export default new Module({
    state: JobState,
    getters: JobGetters,
    mutations: JobMutations,
    actions: JobActions
});