import HttpData from "@/http/data/http-data";

export interface IContractGuestData {
    first_name: null|string;
    last_name: null|string;
    phone_number: null|string;
    email: null|string;
    language: null|string;
}

export default class ContractGuestData extends HttpData {
    first_name: null|string;
    last_name: null|string;
    phone_number: null|string;
    email: null|string;
    language: null|string;


    constructor(data: IContractGuestData) {
        super();

        this.first_name = data.first_name;
        this.last_name = data.last_name;
        this.phone_number = data.phone_number;
        this.email = data.email;
        this.language = data.language;
    }
}