import {Actions, Context} from "vuex-smart-module";
import UnitCheckState from "@/store/modules/unit-check/unit-check-state";
import UnitCheckGetters from "@/store/modules/unit-check/unit-check-getters";
import UnitCheckMutations from "@/store/modules/unit-check/unit-check-mutations";
import esquireClient from "@/modules/esquire-client";
import {AxiosError} from "axios";
import UnitCheckData from "@/http/data/unit-check-data";
import db from "@/modules/vanity-db";
import {Store} from "vuex";
import {store} from "@/store";
import FinishUnitCheckTodoResultPayload
    from "@/types/interfaces/command-payloads/finish-unit-check-todo-result-payload";
import FinishUnitCheckTodoResultCommand from "@/types/commands/finish-unit-check-todo-result-command";
import FinishUnitCheckCommand from "@/types/commands/finish-unit-check-command";
import ResetUnitCheckTodoResultCommand from "@/types/commands/reset-unit-check-todo-result-command";
import _ from "lodash";
import dayjs from "dayjs";

import root from "@/store/modules";
import unit from "@/store/modules/unit";
import user from "@/store/modules/user";

const UNIT_CHECKS_RESOURCE = "unit-checks";

export default class UnitCheckActions extends Actions<UnitCheckState, UnitCheckGetters, UnitCheckMutations, UnitCheckActions> {
    private rootContext!: Context<typeof root>
    private unitContext!: Context<typeof unit>;
    private userContext!: Context<typeof user>;

    $init(_store: Store<typeof store>): void {
        const rootContext = root.context(_store);

        this.rootContext = rootContext;
        this.unitContext = rootContext.modules.unit;
        this.userContext = rootContext.modules.user;

        super.$init(_store);
    }

    async attemptRestoreSelectedUnitCheck(): Promise<void> {
        const unitCheck = await db.selected.get("unit-check");

        if (!unitCheck) return;

        this.mutations.setSelectedUnitCheckId(unitCheck.id as number);
    }

    async finishUnitCheck(unitCheckId: number): Promise<void> {
        if (!this.userContext.state.session) return;

        const unitCheck = _.find(this.state.unitChecks, unitCheck => unitCheck.id === unitCheckId);

        if (!unitCheck) return;

        const command = new FinishUnitCheckCommand(unitCheckId);

        await this.rootContext.actions.onCommand(command);

        if (unitCheck.unit_check_type_id === "check_thoroughly") {
            this.unitContext.mutations.setThoroughInspectionWhereUnitId({
                unitId: unitCheck.unit_id,
                checkedAt: dayjs().toDate(),
                checkedBy: this.userContext.state.session.username
            });

            return;
        }

        this.mutations.finishUnitCheck({
            name: this.userContext.state.session.username,
            unitCheckId: unitCheckId
        });
    }

    async finishUnitCheckTodoResult(payload: FinishUnitCheckTodoResultPayload): Promise<void> {
        const command = new FinishUnitCheckTodoResultCommand(payload, id => {
            this.mutations.finishUnitCheckTodoResult({
                unitCheckTodoResult: payload.unitCheckTodoResult,
                id: id,
                hasIssues: payload.hasIssues
            })
        });

        await this.rootContext.actions.onCommand(command);
    }

    async indexUnitChecks(): Promise<void> {
        const response = await esquireClient.get(UNIT_CHECKS_RESOURCE).catch((e: AxiosError) => e.response);

        if (!response) return;

        if (response.status === 200) {
            const unitChecks = _.map(response.data, data => new UnitCheckData(data));

            this.mutations.setUnitChecks(unitChecks);
        }
    }

    async initialize(): Promise<void> {
        await this.actions.indexUnitChecks();

        this.mutations.setIsInitialized(true);
    }

    async rehydrateStore(): Promise<void> {
        await this.actions.attemptRestoreSelectedUnitCheck();
    }

    async resetUnitCheckTodoResult(id: number): Promise<void> {
        const command = new ResetUnitCheckTodoResultCommand(id);

        await this.rootContext.actions.onCommand(command);

        this.mutations.resetUnitCheckTodoResult(id);
    }

    async selectUnitCheck(unitCheckId: number): Promise<void> {
        await db.selected.put({
            id: unitCheckId
        }, "unit-check");

        await this.mutations.setSelectedUnitCheckId(unitCheckId);
    }

    async startThoroughInspection(unitId: string): Promise<void> {
        const createUnitCheckResponse = await esquireClient.post(UNIT_CHECKS_RESOURCE, {
            unit_id: unitId,
            unit_check_type_id: "check_thoroughly"
        }).catch((e: AxiosError) => e.response);

        if (!createUnitCheckResponse || createUnitCheckResponse.status !== 200) return;

        const url = UNIT_CHECKS_RESOURCE + "/" + (createUnitCheckResponse.data.id as number);

        const getUnitCheckResponse = await esquireClient.get(url).catch((e: AxiosError) => e.response);

        if (!getUnitCheckResponse || getUnitCheckResponse.status !== 200) return;

        const unitCheck = getUnitCheckResponse.data as UnitCheckData;

        this.mutations.addUnitCheck(unitCheck);

        await this.actions.selectUnitCheck(unitCheck.id);
    }
}