import {Mutations} from "vuex-smart-module";
import JobState from "@/store/modules/job/job-state";
import JobData from "@/http/data/job-data";
import ErrorData from "@/http/data/error-data";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import _ from "lodash";
import {JobOverviewDisplayValue} from "@/types/strings";

dayjs.extend(utc);

export default class JobMutations extends Mutations<JobState> {
    addJob(job: JobData): void {
        this.state.jobs.push(job);
    }

    cancelJob({uuid, cancelledBecause, cancelledBy}: {
        uuid: string,
        cancelledBecause: string
        cancelledBy: string
    }): void {
        const job = this.state.jobs.find(job => job.uuid === uuid);

        if (!job) return;

        job.cancelled_at = dayjs().utc(true).toISOString();
        job.cancelled_because = cancelledBecause;
        job.cancelled_by = cancelledBy;
    }

    concatJobs(jobs: JobData[]): void {
        this.state.jobs = this.state.jobs.concat(jobs);
    }

    setIsInitialized(value = true): void {
        this.state.isInitialized = value;
    }

    performJob({uuid, performedBy}: {
        uuid: string,
        performedBy: string
    }): void {
        const job = this.state.jobs.find(job => job.uuid === uuid);

        if (!job) return;

        job.performed_at = dayjs().utc(true).toISOString();
        job.performed_by = performedBy;
    }

    removeJob(job: JobData): void {
        _.remove(this.state.jobs, j => j.uuid === job.uuid);
    }

    setCollapsedJobAccordionItem(value: string): void {
        this.state.collapsedJobAccordionItem = value;
    }

    setShowScheduledJobsOnly(value: boolean): void {
        this.state.showScheduledJobsOnly = value;
    }

    setShowTodayJobsOnly(value: boolean): void {
        this.state.showTodayJobsOnly = value;
    }

    setHttpError(error: null|ErrorData): void {
        this.state.httpError = error;
    }

    setJobId({uuid, id}: {
        uuid: string,
        id: number
    }): void {
        const job = this.state.jobs.find(job => job.uuid === uuid);

        if (!job) return;

        job.id = id;
    }

    setJobOverviewDateFrom(value: null|string): void {
        this.state.jobOverviewDateFrom = value;
    }

    setJobOverviewDateUntil(value: null|string): void {
        this.state.jobOverviewDateUntil = value;
    }

    setJobOverviewDisplay(value: JobOverviewDisplayValue): void {
        this.state.jobOverviewDisplay = value;
    }

    setJobs(jobs: JobData[]): void {
        this.state.jobs = jobs;
    }

    setSelectedNewJobContractId(contractId: null|string): void {
        this.state.selectedNewJobContractId = contractId;
    }

    setSelectedNewJobType(type: string): void {
        this.state.selectedNewJobType = type;
    }

    setSelectedNewJobUnitCheckTodoResultId(unitCheckTodoResultId: null|number): void {
        this.state.selectedNewJobUnitCheckTodoResultId = unitCheckTodoResultId;
    }

    updateJob(updatedJob: JobData): void {
        const job = this.state.jobs.find(job => job.uuid === updatedJob.uuid);

        if (!job) return;

        job.id = updatedJob.id;
        job.building_id = updatedJob.building_id;
        job.cancelled_at = updatedJob.cancelled_at;
        job.cancelled_because = updatedJob.cancelled_because;
        job.cancelled_by = updatedJob.cancelled_by;
        job.checked_at = updatedJob.checked_at;
        job.checked_by = updatedJob.checked_by;
        job.contract_id = updatedJob.contract_id;
        job.created_at = updatedJob.created_at;
        job.created_by = updatedJob.created_by;
        job.description = updatedJob.description;
        job.perform_date = updatedJob.perform_date;
        job.performed_at = updatedJob.performed_at;
        job.performed_by = updatedJob.performed_by;
        job.requires_empty_room = updatedJob.requires_empty_room;
        job.type = updatedJob.type;
        job.unit_check_todo_result_id = updatedJob.unit_check_todo_result_id;
        job.unit_id = updatedJob.unit_id;
    }
}