import ContractData from "@/http/data/contract-data";
import {ContractSearchFieldFilterValue} from "@/types/strings";
import ReservationData from "@/http/data/reservation-data";
import ContractInfoData from "@/http/data/contract-info-data";

export default class ContractState {
    contracts: ContractData[] = [];
    contractSearchFieldFilter: ContractSearchFieldFilterValue = "contract_id";
    isInitialized = false;
    searchedReservations: ReservationData[] = [];
    selectedContractInfo: ContractInfoData|null = null;
    selectedContractInfoId: string|null = null;
}