import JobData from "@/http/data/job-data";
import {HttpResource} from "@/types/enums";
import esquireClient from "@/modules/esquire-client";
import UpdateJobRequest from "@/http/requests/update-job-request";
import {AxiosError} from "axios";
import CommandFailedError from "@/types/errors/command-failed-error";
import ErrorData from "@/http/data/error-data";
import ICommand from "@/types/interfaces/i-command";

export default class PerformJobCommand implements ICommand{
    private job: JobData;

    constructor(job: JobData) {
        this.job = job;
    }

    async execute(): Promise<void> {
        const url = `${HttpResource.Jobs}/${this.job.id}`;

        const response = await esquireClient.put(url, {
            command: "perform",
        } as UpdateJobRequest).catch((e: AxiosError) => e.response);

        if (!response || response.status !== 200) {
            const alert = (response?.data as ErrorData).message;

            throw new CommandFailedError(`Perform job (${this.job.id}) failed.`, alert);
        }
    }
}