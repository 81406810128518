import {Context, Getters} from "vuex-smart-module";
import UserState from "@/store/modules/user/user-state";
import root from "@/store/modules";
import {Store} from "vuex";
import {store} from "@/store";

export default class UserGetters extends Getters<UserState> {
    declare private rootContext: Context<typeof root>;

    $init(_store: Store<typeof store>): void {
        this.rootContext = root.context(_store);

        super.$init(_store);
    }

    get hasSession(): boolean {
        return !!(this.state.session);
    }
}