import UnitCheckData from "@/http/data/unit-check-data";

export default class UnitCheckState {
    isInitialized = false;
    selectedUnitCheckId: null|number = null;
    unitChecks: UnitCheckData[] = [];
    unitCheckIconsByType = {
        check_thoroughly: "fa fa-eye",
        check_before_arrival: "fa fa-sign-in-alt",
        check_outgoing_state: "fa fa-sign-out-alt",
        check_daily_cleaning: "fa fa-broom",
        check_final_cleaning: "fa fa-broom",
        check_moved_contract_cleaning: "fa fa-broom",
        check_weekly_cleaning: "fa fa-broom",
    }
}