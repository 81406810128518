import HttpData from "@/http/data/http-data";

export interface IContractRepresentativeData {
    email: null|string;
    phone_number: null|string;
    language: null|string;
}

export default class ContractRepresentativeData extends HttpData {
    public email: null|string;
    public phone_number: null|string;
    public language: null|string;

    constructor(data: IContractRepresentativeData) {
        super();

        this.email = data.email;
        this.phone_number = data.phone_number;
        this.language = data.language;
    }
}