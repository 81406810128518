export default class AppError extends Error {

    private readonly _alert: string|null;

    constructor(message: string, alert: string|null) {
        super(message);
        this.name = "AppError";
        this._alert = alert;
    }

    getAlert(): string|null {
        return this._alert;
    }

    hasAlert(): boolean {
        return !!this.getAlert();
    }
}