import {Module} from "vuex-smart-module";
import MeterState from "@/store/modules/meter/meter-state";
import MeterGetters from "@/store/modules/meter/meter-getters";
import MeterMutations from "@/store/modules/meter/meter-mutations";
import MeterActions from "@/store/modules/meter/meter-actions";

export default new Module({
    state: MeterState,
    getters: MeterGetters,
    mutations: MeterMutations,
    actions: MeterActions
});