import ContractMeterData from "@/http/data/contract-meter-data";
import ICommand from "@/types/interfaces/i-command";
import PostContractMeterRequest from "@/http/requests/post-contract-meter-request";
import esquireClient from "@/modules/esquire-client";
import {AxiosError} from "axios";
import ErrorData from "@/http/data/error-data";
import CommandFailedError from "@/types/errors/command-failed-error";
import {meterContext} from "@/store";
import _ from "lodash";

export default class PostContractMeterCommand implements ICommand{
    private readonly URL = "meter-entries";

    constructor(
        private meter: ContractMeterData)
    {}

    async execute(): Promise<void> {
        const meter = this.meter;

        const oldMeter = _.find(meterContext.state.contractMeters, m => m.uuid === meter.uuid);

        if (!oldMeter) throw new CommandFailedError(`Couldn't locate old contract meter [id: ${meter.meter_id}].`);

        if (!oldMeter.getNextRequiredDate()) return;

        let nextRequiredDescription;

        switch (oldMeter.getNextRequiredDate()) {
            case meter.min_date:
                nextRequiredDescription = "start of contract";
                break;
            case meter.max_date:
                nextRequiredDescription = "end of contract";
                break;
            default:
                nextRequiredDescription = "price change";
        }

        const request = new PostContractMeterRequest(
            meter.meter_id,
            meter.contract_id,
            nextRequiredDescription,
            meter.value_by_date[oldMeter.getNextRequiredDate() as string] as number,
            meter.force
        );

        console.log(request);

        const response = await esquireClient.post(this.URL, request).catch((e: AxiosError) => e.response);

        if (!response || ![200, 201].includes(response.status)) {
            const alert = (response?.data as ErrorData).message;

            throw new CommandFailedError(`Post contract meter command [meter id:${this.meter.meter_id}] failed.`,
                alert);
        }
    }


}