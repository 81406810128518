import {Getters} from "vuex-smart-module";
import JobState from "@/store/modules/job/job-state";
import _ from "lodash";
import JobData from "@/http/data/job-data";
import {jobContext} from "@/store";

/**
 * Checks if a job exists in the store, and if it does, replaces it with the existing object. Otherwise
 * the job will be added to the store and kept it in the array. If the store is populated by any other means, it's
 * possible that some jobs in the store will end up as a duplicate.
 *
 * @param {JobData[]} jobs The jobs to synchronize.
 * @param {boolean} newJobsShouldIndex Whether the jobs should also show up in the unit overview, as well as
 * the contract info view.
 *
 * @return {JobData[]} The same jobs, but synchronized with the store.
 * */
function createOrSynchronizeCallback(jobs: JobData[], newJobsShouldIndex = true): JobData[] {
    const jobById = _.chain(jobContext.state.jobs)
        .filter((job: JobData) => !!job.id)
        .keyBy((job: JobData) => job.id as number)
        .value();

    const jobByUuid = _.keyBy(jobContext.state.jobs, (job: JobData) => job.uuid);

    const jobsThatAreNotInStore: JobData[] = [];

    const result = _.map(jobs, (job: JobData) => {
        const storeJob = job.id ? jobById[job.id] : jobByUuid[job.uuid];

        if (storeJob) {
            job.uuid = storeJob.uuid;

            if (storeJob.equals(job)) return storeJob;

            jobContext.mutations.updateJob(job);

            return storeJob;
        }

        jobsThatAreNotInStore.push(job);

        return job;
    });

    if (!newJobsShouldIndex) {
        jobsThatAreNotInStore.forEach(job => job.hideFromIndex());
    }

    jobContext.mutations.concatJobs(jobsThatAreNotInStore);

    return result;
}

export default class JobGetters extends Getters<JobState> {
    /**
     * Getter for {@link createOrSynchronizeCallback}
     *
     * @return {@link createOrSynchronizeCallback}
     * */
    get createOrSynchronize(): typeof createOrSynchronizeCallback {
        return createOrSynchronizeCallback;
    }

    get JobById(): _.Dictionary<JobData> {
        return _.chain(this.state.jobs)
            .filter(job => !!job.id)
            .keyBy(job => job.id as number)
            .value();
    }

    get jobsByUnitId(): _.Dictionary<JobData[]> {
        return _.chain(this.state.jobs)
            .groupBy(job => job.unit_id)
            .value();
    }

    get jobsByUnitCheckTodoResultId(): Record<string, JobData[]> {
        return _.chain(this.state.jobs)
            .groupBy(job => job.unit_check_todo_result_id)
            .value();
    }
}