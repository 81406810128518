import {Actions} from "vuex-smart-module";
import ProductState from "@/store/modules/product/product-state";
import ProductGetters from "@/store/modules/product/product-getters";
import ProductMutations from "@/store/modules/product/product-mutations";
import esquireClient from "@/modules/esquire-client";
import {AxiosError} from "axios";
import ProductData from "@/http/data/product-data";
import _ from "lodash";

const URL = "products";

export default class ProductActions extends Actions<ProductState, ProductGetters, ProductMutations, ProductActions> {
    async indexProducts(): Promise<void> {
        const response = await esquireClient.get(URL).catch((e: AxiosError) => e.response);

        if (!response) return;

        if (response.status === 200) {
            const products = _.map(response.data, data => new ProductData(data));

            this.mutations.setProducts(products);
        }
    }

    async initialize(): Promise<void> {
        await this.actions.indexProducts();

        this.mutations.setIsInitialized(true);
    }
}