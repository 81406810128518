import CreateJobRequest from "@/http/requests/create-job-request";
import ICommand from "@/types/interfaces/i-command";
import esquireClient from "@/modules/esquire-client";
import {AxiosError} from "axios";
import {HttpResource} from "@/types/enums";
import CommandFailedError from "@/types/errors/command-failed-error";
import JobData from "@/http/data/job-data";
import ErrorData from "@/http/data/error-data";

export default class CreateJobCommand implements ICommand {
    constructor(private request: CreateJobRequest, private callback: (id: number) => void) {}

    async execute(): Promise<void> {
        const response = await esquireClient.post(HttpResource.Jobs, this.request).catch((e: AxiosError) => e.response);

        if (!response || response.status !== 200) {
            const alert = (response?.data as ErrorData).message;

            throw new CommandFailedError("Create new job command failed.", alert);
        }

        if (response.status === 200) {
            const job = response.data as JobData;

            this.callback(job.id as number);
        }
    }
}