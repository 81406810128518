import {Module} from "vuex-smart-module";
import UserState from "@/store/modules/user/user-state";
import UserGetters from "@/store/modules/user/user-getters";
import UserMutations from "@/store/modules/user/user-mutations";
import UserActions from "@/store/modules/user/user-actions";

export default new Module({
    state: UserState,
    getters: UserGetters,
    mutations: UserMutations,
    actions: UserActions
});