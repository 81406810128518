import ContractClientData, {IContractClientData} from "@/http/data/contract-client-data";
import JobData, {IJobData} from "@/http/data/job-data";
import ContractNoteData, {IContractNoteData} from "@/http/data/contract-note-data";
import _ from "lodash";
import HttpData from "@/http/data/http-data";
import ContractGuestData, {IContractGuestData} from "@/http/data/contract-guest-data";
import ContractRepresentativeData, {IContractRepresentativeData} from "@/http/data/contract-representative-data";
import InvoiceLineData, {IInvoiceLineData} from "@/http/data/invoice-line-data";

export interface IFinancial {
    accrued_debt_today: number;
    accrued_debt_total: number;
    paid_total: number;
    to_pay_today: number;
    to_pay_total: number;
}

export class Financial {
    public accrued_debt_today: number;
    public accrued_debt_total: number;
    public paid_total: number;
    public to_pay_today: number;
    public to_pay_total: number;

    constructor(data: IFinancial) {
        this.accrued_debt_today = data.accrued_debt_today;
        this.accrued_debt_total = data.accrued_debt_total;
        this.paid_total = data.paid_total;
        this.to_pay_today = data.to_pay_today;
        this.to_pay_total = data.to_pay_total;
    }
}

export interface IPaymentMethod {
    id: string;
    description: string;
    is_online_payment: boolean;
}

export class PaymentMethod {
    public id: string;
    public description: string;
    public is_online_payment: boolean;

    constructor(data: IPaymentMethod) {
        this.id = data.id;
        this.description = data.description;
        this.is_online_payment = data.is_online_payment;
    }
}

export interface ISibling {
    id: string;
    unit_id: string|null;
    unit_type_id: string;
    unit_type_name: string;
}

export class Sibling {
    public id: string;
    public unit_id: string;
    public unit_type_id: string;
    public unit_type_name: string;

    constructor(data: ISibling) {
        this.id = data.id;
        this.unit_id = data.unit_id ?? "N/A";
        this.unit_type_id = data.unit_type_id;
        this.unit_type_name = data.unit_type_name;
    }
}

export interface ISource {
    id: string;
    description: string;
}

export class Source {
    public id: string;
    public description: string;

    constructor(data: ISource) {
        this.id = data.id;
        this.description = data.description;
    }
}

export interface IContractInfoData {
    id: string;
    start_date: string;
    end_date: string;
    guest_amount: number;
    unit: {
        id: null|string;
        type_id: string;
        description: string;
        has_inactive_device: boolean;
    };
    reservation: {
        id: string;
        building: {
            id: string;
            name: string;
        };
        financial: IFinancial
    };
    financial: IFinancial;
    siblings: ISibling[];
    client: IContractClientData;
    guest: IContractGuestData;
    representative: IContractRepresentativeData;
    source: ISource;
    payment_method?: IPaymentMethod;
    jobs: IJobData[];
    notes: IContractNoteData[];
    invoice_lines: IInvoiceLineData[];
}

export default class ContractInfoData extends HttpData {
    public id: string;
    public start_date: string;
    public end_date: string;
    public guest_amount: number;
    public unit: {
        id: null|string;
        type_id: string;
        description: string;
        has_inactive_device: boolean
    };
    public reservation: {
        id: string;
        building: {
            id: string;
            name: string;
        };
        financial: Financial
    };
    public financial: Financial;
    public siblings: Sibling[];
    public client: ContractClientData;
    public guest: ContractGuestData;
    public representative: ContractRepresentativeData;
    public source: Source;
    public payment_method: PaymentMethod;
    public jobs: JobData[];
    public notes: ContractNoteData[];
    public invoice_lines: InvoiceLineData[];


    constructor(data: IContractInfoData) {
        super();

        this.id = data.id;
        this.start_date = data.start_date;
        this.end_date = data.end_date;
        this.guest_amount = data.guest_amount;
        this.unit = data.unit;
        this.reservation = data.reservation;
        this.financial = new Financial(data.financial);
        this.siblings = _.map(data.siblings, data => new Sibling(data));
        this.client = new ContractClientData(data.client);
        this.representative = new ContractRepresentativeData(data.representative);
        this.guest = new ContractGuestData(data.guest);
        this.source = new Source(data.source);
        this.payment_method = new PaymentMethod(data.payment_method ?? {
            id: "unknown",
            description: "Unknown",
            is_online_payment: true
        });
        this.jobs = _.map(data.jobs, data => new JobData(data));
        this.notes = _.map(data.notes, data => new ContractNoteData(data));
        this.invoice_lines = _.map(data.invoice_lines, data => new InvoiceLineData(data));
    }
}