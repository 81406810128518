import TodoResultData from "@/http/data/todo-result-data";
import HttpData from "@/http/data/http-data";

export interface IUnitCheckData {
    id: number;
    contract_id?: string;
    unit_id: string;
    unit_check_type_id: string;
    unit_check_type_description: string;
    todo_date: string;
    checked_by?: string;
    checked_at?: string;
    building_id: string;

    todo_results?: TodoResultData[];
}

export default class UnitCheckData extends HttpData {
    public id: number;
    public contract_id: null|string;
    public unit_id: string;
    public unit_check_type_id: string;
    public unit_check_type_description: string;
    public todo_date: string;
    public checked_by: null|string;
    public checked_at: null|string;
    public building_id: string;

    public todo_results: undefined|TodoResultData[];


    constructor(data: IUnitCheckData) {
        super();

        this.id = data.id;
        this.contract_id = data.contract_id ?? null;
        this.unit_id = data.unit_id;
        this.unit_check_type_id = data.unit_check_type_id;
        this.unit_check_type_description = data.unit_check_type_description;
        this.todo_date = data.todo_date;
        this.checked_by = data.checked_by ?? null;
        this.checked_at = data.checked_at ?? null;
        this.building_id = data.building_id;

        this.todo_results = data.todo_results;
    }
}