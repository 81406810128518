import {Mutations} from "vuex-smart-module";
import UserState from "@/store/modules/user/user-state";
import Session from "@/http/data/session-data";
import Error from "@/http/data/error-data";

export default class UserMutations extends Mutations<UserState> {
    setIsInitialized(value = true): void {
        this.state.isInitialized = value;
    }

    setLoginError(error: Error|null): void {
        this.state.loginError = error;
    }

    setRequestInProgress(value: boolean): void {
        this.state.requestInProgress = value;
    }

    setSession(session: null|Session): void {
        this.state.session = session;
    }
}