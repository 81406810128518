import {Mutations} from "vuex-smart-module";
import MeterState from "@/store/modules/meter/meter-state";
import ContractMeterData from "@/http/data/contract-meter-data";
import _ from "lodash";

export default class MeterMutations extends Mutations<MeterState> {
    setContractMeters(contractMeters: ContractMeterData[]): void {
        this.state.contractMeters = contractMeters;
    }

    setIsInitialized(value = true): void {
        this.state.isInitialized = value;
    }

    setSelectedMeterContractId(contractId: string): void {
        this.state.selectedMeterContractId = contractId;
    }

    updateContractMeter(meter: ContractMeterData): void {
        this.state.contractMeters = _.filter(this.state.contractMeters, m => m.uuid !== meter.uuid);

        this.state.contractMeters.push(meter);
    }
}