import {Mutations} from "vuex-smart-module";
import RootState from "@/store/modules/root-state";
import ICommand from "@/types/interfaces/i-command";

export default class RootMutations extends Mutations<RootState> {
    addCommand(command: ICommand): void {
        this.state.commands.push(command);
    }

    sliceCommands(start: number): void {
        this.state.commands = this.state.commands.slice(start);
    }

    setIsOffline(isOffline: boolean): void {
        this.state.isOffline = isOffline;
    }
}