import {createLogger} from "vuex";
import {createStore} from "vuex-smart-module";
import root from "@/store/modules";

const plugins = [];

if (process.env.VUE_APP_VUEX_PLUGIN_LOGGER === "true") plugins.push(createLogger());

export const store = createStore(root, {
    plugins,
    devtools: process.env.NODE_ENV !== "production",
    strict: process.env.NODE_ENV !== "production"
});

export const rootContext = root.context(store);
export const userContext = rootContext.modules.user;
export const buildingContext = rootContext.modules.building;
export const jobContext = rootContext.modules.job;
export const meterContext = rootContext.modules.meter;
export const productContext = rootContext.modules.product;
export const unitCheckContext = rootContext.modules.unitCheck;
export const contractContext = rootContext.modules.contract;
export const unitContext = rootContext.modules.unit;

export function rehydrateStore(): Promise<void[]> {
    const rehydrateBuildingStorePromise = buildingContext.actions.rehydrateStore();
    const rehydrateContractStorePromise = contractContext.actions.rehydrateStore();
    const rehydrateJobStorePromise = jobContext.actions.rehydrateStore();
    const rehydrateMeterStorePromise = meterContext.actions.rehydrateStore();
    const rehydrateUnitStorePromise = unitContext.actions.rehydrateStore();
    const rehydrateUnitCheckStorePromise = unitCheckContext.actions.rehydrateStore();

    return Promise.all([
        rehydrateBuildingStorePromise,
        rehydrateContractStorePromise,
        rehydrateJobStorePromise,
        rehydrateMeterStorePromise,
        rehydrateUnitStorePromise,
        rehydrateUnitCheckStorePromise,
    ]);
}

export function initializeStore(): Promise<void[]> {
    const intervalInMilliseconds = Number.parseInt(process.env.VUE_APP_VUEX_REHYDRATE_INTERVAL_MILLISECONDS ?? "NaN")

    if (!rootContext.getters.isInitialized && Number.isInteger(intervalInMilliseconds)) {
        console.info(`Re-hydrating store every ${intervalInMilliseconds} milliseconds.`);
        setInterval(() => initializeStore(), intervalInMilliseconds);
    }

    const initializeBuildingModulePromise = buildingContext.actions.initialize();
    const initializeContractModulePromise = contractContext.actions.initialize();
    const initializeJobModulePromise = jobContext.actions.initialize();
    const initializeMeterModulePromise = meterContext.actions.initialize();
    const initializeProductModulePromise = productContext.actions.initialize();
    const initializeUnitCheckModulePromise = unitCheckContext.actions.initialize();
    const initializeUnitModulePromise = unitContext.actions.initialize();

    return Promise.all([
        initializeBuildingModulePromise,
        initializeContractModulePromise,
        initializeJobModulePromise,
        initializeMeterModulePromise,
        initializeProductModulePromise,
        initializeUnitCheckModulePromise,
        initializeUnitModulePromise
    ]);
}