import axios from "axios";

axios.defaults.baseURL = process.env.VUE_APP_ESQUIRE_API_V1_URL;

if (process.env.VUE_APP_XDEBUG_SESSION_START) {
    axios.defaults.params = {
        XDEBUG_SESSION_START: process.env.VUE_APP_XDEBUG_SESSION_START
    }
}

export default axios;