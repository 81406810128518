import ICommand from "@/types/interfaces/i-command";
import esquireClient from "@/modules/esquire-client";
import UpdateJobRequest from "@/http/requests/update-job-request";
import {AxiosError} from "axios";
import {HttpResource} from "@/types/enums";
import CommandFailedError from "@/types/errors/command-failed-error";
import JobData from "@/http/data/job-data";
import ErrorData from "@/http/data/error-data";

export default class CancelJobCommand implements ICommand {
    private job: JobData;
    private reason: string;

    constructor(job: JobData, reason: string) {
        this.job = job;
        this.reason = reason;
    }

    async execute(): Promise<void> {
        const url = `${HttpResource.Jobs}/${this.job.id}`;

        const response = await esquireClient.put(url, {
            command: "cancel",
            cancelled_because: this.reason
        } as UpdateJobRequest).catch((e: AxiosError) => e.response);

        if (!response || response.status !== 200) {
            const alert = (response?.data as ErrorData).message;

            throw new CommandFailedError(`Cancel job (${this.job.id}) failed.`, alert);
        }
    }

}