import {Context, Getters} from "vuex-smart-module";
import UnitState from "@/store/modules/unit/unit-state";
import UnitData from "@/http/data/unit-data";
import ContractData from "@/http/data/contract-data";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import isBetween from "dayjs/plugin/isBetween";
import dayjs from "dayjs";
import _ from "lodash";
import {store} from "@/store";
import {Store} from "vuex";

import root from "@/store/modules";
import contract from "@/store/modules/contract";
import BuildingData from "@/http/data/building-data";

dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);
dayjs.extend(isBetween);

export default class UnitGetters extends Getters<UnitState> {
    private contractContext!: Context<typeof contract>;

    $init(_store: Store<typeof store>): void {
        this.contractContext = root.context(_store).modules.contract;
        
        super.$init(_store);
    }

    get unitsByBuildingId(): Record<string, UnitData[]> {
        return _.groupBy(this.state.units, unit => unit.unit_type_id.substring(0, 2));
    }

    get unitsById(): _.Dictionary<UnitData> {
        return _.chain(this.state.units)
            .filter(unit => !!unit.id)
            .keyBy(unit => unit.id as string)
            .value();
    }
    
    get unitStatusByUnitId(): _.Dictionary<string> {
        const contractsByUnitId = this.contractContext.getters.activeContractByUnitId;

        return _.chain(this.getters.unitsById)
            .mapValues((unit, unitId) => {
                const contract = contractsByUnitId[unitId] ?? [];
                
                if (unit.blockade) return "status-blocked";

                if (!contract || this.getters.hasDeparted(contract)) return "status-vacant";

                if (this.getters.isArriving(contract)) return "status-arriving"

                if (this.getters.isDeparting(contract)) return "status-departing"

                return contract.key_pickup_at !== null ? "status-occupied" : "status-vacant";
            })
            .value();
    }
    
    get hasArrived(): (contract: ContractData) => boolean {
        return (contract: ContractData): boolean => {
            return this.getters.isSameOrAfterStartDate(contract) && contract.key_pickup_at !== null;
        }
    }

    get hasDeparted(): (contract: ContractData) => boolean {
        return (contract: ContractData): boolean => {
            return contract.key_pickup_at !== null && contract.key_back_at !== null;
        }
    }
    
    get isArriving(): (contract: ContractData) => boolean {
        return (contract: ContractData): boolean => {
            return this.getters.isSameOrAfterStartDate(contract) && contract.key_pickup_at === null;
        }
    }

    get isDeparting(): (contract: ContractData) => boolean {
        return (contract: ContractData):boolean => {
            return this.getters.isSameOrAfterDepartureDate(contract) && contract.key_back_at === null;
        }
    }

    get isSameOrAfterDepartureDate(): (contract: ContractData) => boolean {
        return (contract: ContractData): boolean => {
            return dayjs().isSameOrAfter(contract.end_date, "day");
        }
    }

    get isSameOrAfterStartDate(): (contract: ContractData) => boolean {
        return (contract: ContractData): boolean => {
            return dayjs().isSameOrAfter(contract.start_date, "day");
        }
    }

    get parseRoomNumber(): (unitId: string) => string {
        return (unitId: string): string => {
            return unitId.substring(2);
        }
    }

    get selectedUnit(): null|UnitData {
        if (!this.state.selectedUnitId) return null;

        return this.getters.unitsById[this.state.selectedUnitId];
    }

    get selectedUnitActiveContract(): null|ContractData {
        const selectedUnit = this.getters.selectedUnit;

        if (!selectedUnit || !selectedUnit.id) return null;

        const contractByUnitId = this.contractContext.getters.activeContractByUnitId;

        return contractByUnitId[selectedUnit.id];
    }

    get hasInactiveDevice(): null|boolean {
        const selectedUnit = this.getters.selectedUnit;

        if (!selectedUnit || !selectedUnit.id) return null;

        return selectedUnit?.has_inactive_device;
    }

    get selectedUnitContracts(): ContractData[] {
        const selectedUnit = this.getters.selectedUnit;

        if (!selectedUnit || !selectedUnit.id) return [];

        const contractsByUnitId = this.contractContext.getters.contractsByUnitId;

        return contractsByUnitId[selectedUnit.id];
    }

    get selectedUnitStatus(): null|string {
        if (!this.state.selectedUnitId) return null;
        return this.getters.unitStatusByUnitId[this.state.selectedUnitId] ?? null;
    }

    get statusTime(): (contract: ContractData|null, status: string, building: BuildingData) => string {
        return ((contract: ContractData|null, status: string, building: BuildingData) => {
            const day = dayjs().format("ddd").toLowerCase() as "mon"|"tue"|"wed"|"thu"|"fri"|"sat"|"sun";

            if (!contract) {
                return "-";
            }
            else if (status === "status-arriving") {
                const prefix = dayjs().isSame(contract.start_date, "day") ?
                    "" : dayjs(contract.start_date).format("MMM D") + ", ";

                let checkinTime = "16:00:00";

                if (contract.reception_modifiers &&
                    contract.reception_modifiers.includes("early_checkin_11h")) {
                    checkinTime = "11:00:00";
                } else if (building.reception_times && day in building.reception_times.checkin) {
                    checkinTime = building.reception_times.checkin[day].from;
                }

                const checkinHour = dayjs(checkinTime, "HH:mm:ss").format("HH:mm");

                return prefix + checkinHour;
            }
            else if (status === "status-departing") {
                let checkoutTime = "10:00:00";

                if (contract.reception_modifiers &&
                    contract.reception_modifiers.includes("late_checkout_19h")) {
                    checkoutTime = "19:00:00";
                } else if (contract.reception_modifiers &&
                    contract.reception_modifiers.includes("late_checkout_13h")) {
                    checkoutTime = "13:00:00";
                } else if (building.reception_times && day in building.reception_times.checkout) {
                    checkoutTime = building.reception_times.checkout[day].until;
                }

                const checkoutHour = dayjs(checkoutTime, "HH:mm:ss");

                const endDate = dayjs(contract.end_date)
                    .set("hour", checkoutHour.hour())
                    .set("minutes", checkoutHour.minute());

                if (dayjs().isAfter(endDate)) return "KEYBACK OVERDUE";

                return endDate.format("HH:mm");
            }

            return "-";
        })
    }
}