import {Actions} from "vuex-smart-module";
import RootState from "@/store/modules/root-state";
import RootGetters from "@/store/modules/root-getters";
import RootMutations from "@/store/modules/root-mutations";
import ICommand from "@/types/interfaces/i-command";
import CommandFailedError from "@/types/errors/command-failed-error";
import eventBus from "@/modules/event-bus";
import {ElMessageBoxOptions} from "element-plus/packages/message-box/src/message-box.type";

export default class RootActions extends Actions<RootState, RootGetters, RootMutations, RootActions> {
    private $events = eventBus;

    async message(options: ElMessageBoxOptions): Promise<void> {
        this.$events.emit("message", options);
    }

    async runCommands(): Promise<void> {
        let performedCommands = 0;

        for (const command of this.state.commands) {
            try {
                await command.execute();
            } catch (e) {
                await this.actions.onError(e);
            }

            performedCommands++;
        }

        this.mutations.sliceCommands(performedCommands);
    }

    async onCommand(command: ICommand): Promise<void> {
        const offlineModeIsPermitted = process.env.VUE_APP_PERMIT_OFFLINE_MODE === "true";

        if (this.state.isOffline) {
            if (offlineModeIsPermitted) {
                this.mutations.addCommand(command);
                return;
            }

            await this.actions.onError(new CommandFailedError(
                "No internet connection.",
                "Unable to connect to server. Please ensure you are connected to the internet."));
        }

        try {
            await command.execute();
        } catch (e) {
            await this.actions.onError(e);
        }
    }

    async onError(e: Error): Promise<void> {
        if (e instanceof CommandFailedError) {
            await this.actions.handleCommandFailedError(e);
        }

        throw e;
    }

    async handleCommandFailedError(e: CommandFailedError): Promise<void> {
        const alert = e.getAlert() ?? "Action failed. Server did not return a response."

        await this.actions.message({
            message: alert,
            type: "error",
            duration: 0,
            showClose: true
        });
    }
}