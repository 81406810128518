import HttpData from "@/http/data/http-data";

export interface ISessionData {
    username: string;
    full_name: string;
    token: string;
    roles: string[];
    abilities: string[];
}

export default class SessionData extends HttpData {
    public username: string;
    public full_name: string;
    public token: string;
    public roles: string[];
    public abilities: string[];


    constructor(data: ISessionData) {
        super();

        this.username = data.username;
        this.full_name = data.full_name;
        this.token = data.token;
        this.roles = data.roles;
        this.abilities = data.abilities;
    }
}