import {Getters} from "vuex-smart-module";
import UnitCheckState from "@/store/modules/unit-check/unit-check-state";
import _ from "lodash";
import UnitCheckData from "@/http/data/unit-check-data";

export default class UnitCheckGetters extends Getters<UnitCheckState> {
    get unitCheckAbbreviatedDescriptionsByTypeId(): Record<string, string> {
        return _.chain(this.state.unitChecks)
            .keyBy(unitCheck => unitCheck.unit_check_type_id)
            .mapValues(unitCheck => {
                switch (unitCheck.unit_check_type_description) {
                    case "CHECK BEFORE ARRIVAL":
                        return "CBA";
                    case "THOROUGH INSPECTION":
                        return "INSPECT"
                }

                const words = _.chain(unitCheck.unit_check_type_description)
                    .split(" ")
                    .map(word => word.toLowerCase())
                    .value();

                const containsCleaning = _
                    .chain(words)
                    .every(word => word !== "cleaning")
                    .value();

                let chain = _.chain(words).filter(word => word !== "check" && word !== "cleaning");

                if (containsCleaning) {
                    chain = chain.map(word => word[0]);
                }

                const abbreviation = chain.join("").value();

                return _.toUpper(abbreviation);
            })
            .value();
    }

    get unitChecksByUnitId(): Record<string, UnitCheckData[]> {
        return _.chain(this.state.unitChecks)
            .groupBy(unitCheck => unitCheck.unit_id)
            .value();
    }
}