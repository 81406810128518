import {Mutations} from "vuex-smart-module";
import ProductState from "@/store/modules/product/product-state";
import ProductData from "@/http/data/product-data";

export default class ProductMutations extends Mutations<ProductState> {
    setIsInitialized(value = true): void {
        this.state.isInitialized = value;
    }

    setProducts(products: ProductData[]): void {
        this.state.products = products;
    }
}