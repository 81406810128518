import {Getters} from "vuex-smart-module";
import BuildingState from "@/store/modules/building/building-state";
import BuildingData from "@/http/data/building-data";
import _ from "lodash";

export default class BuildingGetters extends Getters<BuildingState> {
    get buildingById(): _.Dictionary<BuildingData> {
        return _.chain(this.state.buildings)
            .filter(building => !!building.id)
            .keyBy(building => building.id as string)
            .value();
    }

    get selectedBuilding(): null|BuildingData {
        if (!this.state.selectedBuildingId) return null;

        return this.getters.buildingById[this.state.selectedBuildingId];
    }
}