import {Actions} from "vuex-smart-module";
import UnitState from "@/store/modules/unit/unit-state";
import UnitGetters from "@/store/modules/unit/unit-getters";
import UnitMutations from "@/store/modules/unit/unit-mutations";
import db from "@/modules/vanity-db";
import esquireClient from "@/modules/esquire-client";
import {AxiosError} from "axios";
import UnitData from "@/http/data/unit-data";
import _ from "lodash";

export default class UnitActions extends Actions<UnitState, UnitGetters, UnitMutations, UnitActions> {
    async attemptRestoreSelectedUnit(): Promise<void> {
        const selectedUnit = await db.selected.get("unit");

        if (!selectedUnit) return;

        this.mutations.setSelectedUnitId(selectedUnit.id as string);
    }

    async indexUnits(): Promise<void> {
        const url = "units";

        const response = await esquireClient.get(url).catch((e: AxiosError) => e.response);

        if (!response) return;

        if (response.status === 200) {
            const units = _.map(response.data, data => new UnitData(data));

            this.mutations.setUnits(units);
        }
    }

    async initialize(): Promise<void> {
        await this.actions.indexUnits();

        this.mutations.setIsInitialized(true);
    }

    async rehydrateStore(): Promise<void> {
        await this.actions.attemptRestoreSelectedUnit();
    }

    async selectUnit(unitId: string): Promise<void> {
        await db.selected.put({
            id: unitId
        }, "unit");

        this.mutations.setSelectedUnitId(unitId);
    }
}