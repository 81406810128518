import {Context, Getters} from "vuex-smart-module";
import RootState from "@/store/modules/root-state";
import {Store} from "vuex";
import {store} from "@/store";
import root from "@/store/modules/index";

export default class RootGetters extends Getters<RootState> {
    declare private rootContext: Context<typeof root>;

    $init(_store: Store<typeof store>): void {
        this.rootContext = root.context(_store);

        super.$init(_store);
    }

    get isInitialized(): boolean {
        const modules =  this.rootContext.modules;

        return modules.building.state.isInitialized
            && modules.contract.state.isInitialized
            && modules.job.state.isInitialized
            && modules.meter.state.isInitialized
            && modules.product.state.isInitialized
            && modules.unit.state.isInitialized
            && modules.unitCheck.state.isInitialized
            && modules.user.state.isInitialized;
    }
}