import {Actions, Context} from "vuex-smart-module";
import esquireClient from "@/modules/esquire-client";
import {AxiosError} from "axios";
import ContractState from "@/store/modules/contract/contract-state";
import ContractGetters from "@/store/modules/contract/contract-getters";
import ContractMutations from "@/store/modules/contract/contract-mutations";
import ContractData from "@/http/data/contract-data";
import _ from "lodash";
import {ContractSearchFieldFilterValue} from "@/types/strings";
import db from "@/modules/vanity-db";
import SearchReservationRequest from "@/http/requests/search-contract-request";
import ReservationData from "@/http/data/reservation-data";
import ContractInfoData from "@/http/data/contract-info-data";
import root from "@/store/modules";
import job from "@/store/modules/job";
import {Store} from "vuex";
import {store} from "@/store";

export default class ContractActions extends Actions<ContractState, ContractGetters, ContractMutations, ContractActions> {
    declare private rootContext: Context<typeof root>
    declare private jobContext: Context<typeof job>

    $init(_store: Store<typeof store>): void {
        const rootContext = root.context(_store);

        this.rootContext = root.context(_store);
        this.jobContext = rootContext.modules.job;

        super.$init(_store);
    }

    async attemptRestoreContractInfoId(): Promise<void> {
        const contractInfo = await db.selected.get("contract-info");

        if (!contractInfo) return;

        this.mutations.setSelectedContractInfoId(contractInfo.id as string);
    }

    async attemptRestoreContractSearchFieldValue(): Promise<void> {
        const contractSearchFieldFilter = await db.selected.get("contract-search-field-filter");

        if (!contractSearchFieldFilter) return;

        this.mutations.setContractSearchFieldFilter(contractSearchFieldFilter.id as ContractSearchFieldFilterValue);
    }

    async fetchContractInfo(): Promise<void> {
        if (!this.state.selectedContractInfoId) return;

        const url = `contracts/${this.state.selectedContractInfoId}`;

        const response = await esquireClient.get(url).catch((e: AxiosError) => e.response);

        if (!response) return;

        if (response.status === 200) {
            const contractInfo = new ContractInfoData(response.data);

            contractInfo.jobs = this.jobContext.getters.createOrSynchronize(contractInfo.jobs, false);

            this.mutations.setSelectedContractInfo(contractInfo);
        }
    }

    async indexContracts(): Promise<void> {
        const url = "contracts";

        const response = await esquireClient.get(url).catch((e: AxiosError) => e.response);

        if (!response) return;

        if (response.status === 200) {
            const contracts = _.map(response.data, data => new ContractData(data));

            this.mutations.setContracts(contracts);
        }
    }

    async initialize(): Promise<void> {
        await this.actions.indexContracts();

        this.mutations.setIsInitialized(true);
    }

    async rehydrateStore(): Promise<void> {
        await this.actions.attemptRestoreContractInfoId();
        await this.actions.attemptRestoreContractSearchFieldValue();
    }

    async searchReservation(params: SearchReservationRequest): Promise<void> {
        const url = "contracts/search";

        this.mutations.setSearchedReservations([]);

        const response = await esquireClient
            .get(url, {
                params: params
            })
            .catch((e: AxiosError) => e.response);

        if (!response) return;

        if (response.status === 200) {
            const reservations = _.map(response.data, data => new ReservationData(data));

            this.mutations.setSearchedReservations(reservations);
        }
    }

    async selectContractSearchFieldFilter(value: ContractSearchFieldFilterValue): Promise<void> {
        await db.selected.put({
            id: value
        }, "contract-search-field-filter");

        this.mutations.setContractSearchFieldFilter(value);
    }

    async selectContractInfoId(contractId: string|null): Promise<void> {
        if (contractId) {
            await db.selected.put({
                id: contractId
            }, "contract-info");
        } else {
            await db.selected.delete("contract-info");
        }

        this.mutations.setSelectedContractInfoId(contractId);

        if (this.state.selectedContractInfo && this.state.selectedContractInfo.id !== contractId) {
            this.mutations.setSelectedContractInfo(null);
        }
    }
}