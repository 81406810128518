import HttpData from "@/http/data/http-data";

export type ReceptionType = "checkin" | "checkout";

export type ReceptionDay = "mon" | "tue" | "wed" | "thu" | "fri" | "sat" | "sun";

export type ReceptionMoments = {
    from: string;
    until: string;
};

export type ReceptionTimes = Record<ReceptionType, Record<ReceptionDay, ReceptionMoments>>;

export interface IBuildingData {
    id?: string;
    name: string;
    address: string;
    zip: string;
    city: string;
}

export default class BuildingData extends HttpData {
    public id: null|string;
    public name: string;
    public address: string;
    public zip: string;
    public city: string;

    public reception_times: undefined | ReceptionTimes;

    constructor(data: IBuildingData) {
        super();

        this.id = data.id ?? null;
        this.name = data.name;
        this.address = data.address;
        this.zip = data.zip;
        this.city = data.city;
    }
}