import UnitBlockadeData from "@/http/data/unit-blockade-data";
import HttpData from "@/http/data/http-data";

export interface IUnitData {
    id: string;
    unit_type_id: string;
    unit_type_description: null|string;
    last_unit_inspection_by: undefined|string;
    last_unit_inspection_date: undefined|string;
    last_occupation_date: undefined|string;
    has_inactive_device: boolean;

    blockade: undefined|UnitBlockadeData;
}

export default class UnitData extends HttpData {
    public id: string;
    public unit_type_id: string;
    public unit_type_description: null|string;
    public last_unit_inspection_by: null|string;
    public last_unit_inspection_date: null|string;
    public last_occupation_date: null|string;
    public has_inactive_device: boolean;

    public blockade: undefined|UnitBlockadeData;


    constructor(data: IUnitData) {
        super();

        this.id = data.id;
        this.unit_type_id = data.unit_type_id;
        this.unit_type_description = data.unit_type_description;
        this.last_unit_inspection_by = data.last_unit_inspection_by ?? null;
        this.last_unit_inspection_date = data.last_unit_inspection_date ?? null;
        this.last_occupation_date = data.last_occupation_date ?? null;
        this.has_inactive_device = data.has_inactive_device ?? false;

        this.blockade = data.blockade;
    }
}