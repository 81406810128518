import {
    createRouter,
    createWebHistory,
    NavigationGuardNext,
    RouteLocationNormalized,
    RouteRecordRaw
} from "vue-router";
import {
    userContext,
    unitCheckContext,
    initializeStore, contractContext, rehydrateStore, buildingContext
} from "@/store";

const routes: Array<RouteRecordRaw> = [
    {
        path: "/",
        name: "Buildings",
        meta: {
            title: "Buildings"
        },
        component: () => import("../views/Buildings.vue"),
    },
    {
        path: "/contract",
        name: "Contract",
        meta: {
            title: "Contract"
        },
        component: () => import("../views/ContractInfo.vue"),
    },
    {
        path: "/contract/search",
        name: "ContractSearch",
        meta: {
            title: "Search contract"
        },
        component: () => import("../views/ContractSearch.vue"),
    },
    {
        path: "/dashboard",
        name: "Dashboard",
        meta: {
            title: "Dashboard"
        },
        component: () => import("../views/Dashboard.vue"),
    },
    {
        path: "/jobs",
        name: "Jobs",
        meta: {
            title: "Jobs"
        },
        component: () => import("../views/Jobs.vue"),
    },
    {
        path: "/job/create",
        name: "JobCreate",
        meta: {
            title: "Create Job"
        },
        component: () => import("../views/JobCreate.vue"),
    },
    {
        path: "/job/new",
        name: "JobNew",
        meta: {
            title: "New Job"
        },
        component: () => import("../views/JobNew.vue"),
    },
    {
        path: "/login",
        name: "Login",
        meta: {
            title: "Login"
        },
        component: () => import("../views/Login.vue"),
    },
    {
        path: "/meters",
        name: "Meters",
        meta: {
            title: "Meters"
        },
        component: () => import("../views/Meters.vue"),
    },
    {
        path: "/units",
        name: "Units",
        meta: {
            title: "Units"
        },
        component: () => import("../views/Units.vue"),
    },
    {
        path: "/unit",
        name: "Unit",
        meta: {
            title: "Unit"
        },
        component: () => import("../views/Unit.vue"),
    },
    {
        path: "/unit/check",
        name: "UnitCheck",
        meta: {
            title: "Unit check"
        },
        component: () => import("../views/UnitCheck.vue"),
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
});

function guard(to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext): void {
    if (to.name === "UnitCheck" && !unitCheckContext.state.selectedUnitCheckId) {
        next({name: "Unit"});
    } else if (to.name === "Contract" && !contractContext.state.selectedContractInfoId) {
        next({name: "ContractSearch"});
    } else {
        next();
    }
}

async function resolveInitError() {
    await userContext.actions.logout();

    await router.push({name: "Login"});
}

router.beforeEach((to, from, next) => {
    if (to.name !== "Login" && !userContext.getters.hasSession) {
        userContext.actions.initialize().then(() => {
            if (userContext.getters.hasSession) {
                rehydrateStore()
                    .then(() => {
                        guard(to, from, next);

                        initializeStore().then(() => {
                            if (buildingContext.state.buildings.length === 0) {
                                return resolveInitError();
                            }
                        });
                    })
                    .catch(() => resolveInitError());
            } else {
                next({name: "Login"});
            }
        });
    } else {
        guard(to, from, next);
    }
});

export default router;
