import {Module} from "vuex-smart-module";
import ProductState from "@/store/modules/product/product-state";
import ProductGetters from "@/store/modules/product/product-getters";
import ProductMutations from "@/store/modules/product/product-mutations";
import ProductActions from "@/store/modules/product/product-actions";

export default new Module({
    state: ProductState,
    getters: ProductGetters,
    mutations: ProductMutations,
    actions: ProductActions
});