import {Module} from "vuex-smart-module";
import RootState from "@/store/modules/root-state";
import RootGetters from "@/store/modules/root-getters";
import RootMutations from "@/store/modules/root-mutations";
import RootActions from "@/store/modules/root-actions";
import building from "@/store/modules/building";
import contract from "@/store/modules/contract";
import job from "@/store/modules/job";
import meter from "@/store/modules/meter";
import product from "@/store/modules/product";
import unit from "@/store/modules/unit";
import unitCheck from "@/store/modules/unit-check";
import user from "@/store/modules/user";

export default new Module({
    state: RootState,
    getters: RootGetters,
    mutations: RootMutations,
    actions: RootActions,
    modules: {
        building,
        contract,
        job,
        meter,
        product,
        unit,
        unitCheck,
        user
    }
});