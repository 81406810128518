import HttpData from "@/http/data/http-data";

export interface IContractNoteData {
    type_id: string;
    type_description?: string;
    description: string;
    created_at: null|string;
    created_by: string;
}

export default class ContractNoteData extends HttpData {
    public type_id: string;
    public type_description: null|string;
    public description: string;
    public created_at: null|string;
    public created_by: string;

    constructor(data: IContractNoteData) {
        super();

        this.type_id = data.type_id;
        this.type_description = data.type_description ?? null;
        this.description = data.description;
        this.created_at = data.created_at;
        this.created_by = data.created_by;
    }
}