import {Mutations} from "vuex-smart-module";
import UnitCheckState from "@/store/modules/unit-check/unit-check-state";
import UnitCheckData from "@/http/data/unit-check-data";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import TodoResultData from "@/http/data/todo-result-data";
import _ from "lodash";

dayjs.extend(utc);

export default class UnitCheckMutations extends Mutations<UnitCheckState> {
    addUnitCheck(unitCheck: UnitCheckData): void {
        this.state.unitChecks.push(unitCheck);
    }

    setIsInitialized(value = true): void {
        this.state.isInitialized = value;
    }

    finishUnitCheck({unitCheckId, name}: {unitCheckId: number, name: string}): void {
        const unitCheck = this.state.unitChecks.find(uc => uc.id === unitCheckId);

        if (!unitCheck) return;

        unitCheck.checked_by = name;
        unitCheck.checked_at = dayjs().utc(true).toISOString();
    }

    finishUnitCheckTodoResult({unitCheckTodoResult, id, hasIssues}: {
        unitCheckTodoResult: TodoResultData,
        id: number,
        hasIssues: boolean
    }): void {
        unitCheckTodoResult.id = id;
        unitCheckTodoResult.has_issues = hasIssues;
        unitCheckTodoResult.is_checked = true;
    }

    resetUnitCheckTodoResult(id: number): void {
        const unitCheckTodoResult = _.chain(this.state.unitChecks)
            .flatMap(unitCheck => unitCheck.todo_results)
            .find(todoResult => !!todoResult && todoResult.id === id)
            .value();

        if (!unitCheckTodoResult) return;

        unitCheckTodoResult.has_issues = false;
        unitCheckTodoResult.is_checked = false;
    }

    setSelectedUnitCheckId(unitCheckId: null|number): void {
        this.state.selectedUnitCheckId = unitCheckId;
    }

    setUnitChecks(unitChecks: UnitCheckData[]): void {
        this.state.unitChecks = unitChecks;
    }
}