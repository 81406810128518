import {Module} from "vuex-smart-module";
import UnitState from "@/store/modules/unit/unit-state";
import UnitGetters from "@/store/modules/unit/unit-getters";
import UnitMutations from "@/store/modules/unit/unit-mutations";
import UnitActions from "@/store/modules/unit/unit-actions";

export default new Module({
    state: UnitState,
    getters: UnitGetters,
    mutations: UnitMutations,
    actions: UnitActions
});