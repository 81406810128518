import {Module} from "vuex-smart-module";
import BuildingState from "@/store/modules/building/building-state";
import BuildingGetters from "@/store/modules/building/building-getters";
import BuildingActions from "@/store/modules/building/building-actions";
import BuildingMutations from "@/store/modules/building/building-mutations";

export default new Module({
    state: BuildingState,
    getters: BuildingGetters,
    mutations: BuildingMutations,
    actions: BuildingActions
});