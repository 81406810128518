import {Actions, Context} from "vuex-smart-module";
import esquireClient from "@/modules/esquire-client";
import {AxiosError} from "axios";
import MeterState from "@/store/modules/meter/meter-state";
import MeterGetters from "@/store/modules/meter/meter-getters";
import MeterMutations from "@/store/modules/meter/meter-mutations";
import ContractMeterData from "@/http/data/contract-meter-data";

import _ from "lodash";
import db from "@/modules/vanity-db";
import PostContractMeterCommand from "@/types/commands/post-contract-meter-command";
import {Store} from "vuex";
import {store} from "@/store";
import root from "@/store/modules";

export default class MeterActions extends Actions<MeterState, MeterGetters, MeterMutations, MeterActions> {
    declare private _rootContext: Context<typeof root>

    $init(_store: Store<typeof store>): void {
        this._rootContext = root.context(_store);

        super.$init(_store);
    }

    async attemptRestoreSelectedMeterContract(): Promise<void> {
        const meterContract = await db.selected.get("meter-contract");

        if (!meterContract) return;

        this.mutations.setSelectedMeterContractId(meterContract.id as string);
    }

    async indexContractMeters(): Promise<void> {
        const url = "meters";

        const response = await esquireClient
            .get(url)
            .catch((e: AxiosError) => e.response);

        if (!response) return;

        if (response.status === 200) {
            const contractMeters = _.map(response.data, data => {
                return new ContractMeterData(data);
            });

            this.mutations.setContractMeters(contractMeters);
        }
    }

    async initialize(): Promise<void> {
        await this.actions.indexContractMeters();

        this.mutations.setIsInitialized(true);
    }

    async postContractMeter(meter: ContractMeterData): Promise<void> {
        const command = new PostContractMeterCommand(meter);

        await this._rootContext.actions.onCommand(command);

        this.mutations.updateContractMeter(meter);
    }

    async rehydrateStore(): Promise<void> {
        await this.actions.attemptRestoreSelectedMeterContract();
    }

    async selectMeterContractId(contractId: string): Promise<void> {
        await db.selected.put({
            id: contractId
        }, "meter-contract");

        this.mutations.setSelectedMeterContractId(contractId);
    }
}