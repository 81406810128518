import HttpData from "@/http/data/http-data";

import dayjs from "dayjs";
import _ from "lodash";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import ICloneable from "@/types/interfaces/i-cloneable";

dayjs.extend(isSameOrAfter);

export interface IContractMeterData {
    meter_id: number;
    meter_type: string;
    contract_id: string;
    unit_id: string;
    building_id: string;
    contract_start_date: string;
    contract_end_date: string;
    limit?: number;
    value_by_date: Record<string, number|null>;
    force: boolean;
}

export default class ContractMeterData extends HttpData implements ICloneable<ContractMeterData> {
    public meter_id: number;
    public meter_type: string;
    public contract_id: string;
    public unit_id: string;
    public building_id: string;
    public contract_start_date: string;
    public contract_end_date: string;
    public limit: null|number;
    public force: boolean;

    private readonly _min_date: string;
    private readonly _max_date: string;

    private readonly _value_by_date: Record<string, number|null>;

    private _next_required_date: string|null;


    constructor(data: IContractMeterData) {
        super();

        this.meter_id = data.meter_id;
        this.meter_type = data.meter_type;
        this.contract_id = data.contract_id;
        this.unit_id = data.unit_id;
        this.building_id = data.building_id;
        this.contract_start_date = data.contract_start_date;
        this.contract_end_date = data.contract_end_date;
        this.limit = data.limit ?? null;
        this.force = data.force ?? null;
        this._value_by_date = data.value_by_date;

        this._min_date = _.min(Object.keys(this._value_by_date)) as string;
        this._max_date = _.max(Object.keys(this._value_by_date)) as string;

        this._next_required_date = this.determineNextRequiredDate();
    }

    get max_date(): string {
        return this._max_date;
    }

    get min_date(): string {
        return this._min_date;
    }

    get value_by_date(): Record<string, number|null> {
        return Object.freeze({...this._value_by_date});
    }

    private determineNextRequiredDate(): string|null {
        return _.chain(this._value_by_date)
            .keys()
            .orderBy((date: string) => date, ["desc"])
            .find((date: string) => {
                return dayjs().isSameOrAfter(date) && !this._value_by_date[date];
            })
            .value() ?? null as string|null;
    }

    public clone(): ContractMeterData {
        const clone = new ContractMeterData(
            {
                meter_id: this.meter_id,
                meter_type: this.meter_type,
                contract_id: this.contract_id,
                unit_id: this.unit_id,
                building_id: this.building_id,
                contract_start_date: this.contract_start_date,
                contract_end_date: this.contract_end_date,
                limit: this.limit ?? undefined,
                force: this.force ?? undefined,
                value_by_date: {...this._value_by_date},
            }
        );

        clone.uuid = this.uuid;

        return clone;
    }

    public getNextRequiredDate(): string|null {
        return this._next_required_date;
    }

    public setNextRequiredValue(value: number): void {
        if (!this._next_required_date) return;

        this._value_by_date[this._next_required_date] = value;

        this._next_required_date = this.determineNextRequiredDate();
    }
}