import HttpData from "@/http/data/http-data";

export interface IInvoiceLineData {
    type_id: string;
    type_name?: string;
    description: string;
    start_date: string;
    end_date: string;
    payable_at: string;
    price: number;
    vat_price: number;
    vat_percentage: number;
}

export default class InvoiceLineData extends HttpData {
    public type_id: string;
    public type_name: null|string;
    public description: string;
    public start_date: string;
    public end_date: string;
    public payable_at: string;
    public price: number;
    public vat_price: number;
    public vat_percentage: number;

    constructor(data: IInvoiceLineData) {
        super();

        this.type_id = data.type_id;
        this.type_name = data.type_name ?? null;
        this.description = data.description;
        this.start_date = data.start_date;
        this.end_date = data.end_date;
        this.payable_at = data.payable_at;
        this.price = data.price;
        this.vat_price = data.vat_price;
        this.vat_percentage = data.vat_percentage;
    }
}