import {Context, Getters} from "vuex-smart-module";
import ContractState from "@/store/modules/contract/contract-state";
import ContractData from "@/http/data/contract-data";
import {Store} from "vuex";
import {store} from "@/store";
import root from "@/store/modules";
import unit from "@/store/modules/unit";
import {ContractLifeCycleStage, ContractStatus} from "@/types/strings";
import _ from "lodash";
import dayjs from "dayjs";

export default class ContractGetters extends Getters<ContractState> {
    private unitContext!: Context<typeof unit>;

    $init(_store: Store<typeof store>): void {
        this.unitContext = root.context(_store).modules.unit;

        super.$init(_store);
    }

    get  activeContractByUnitId(): _.Dictionary<ContractData> {
        const contractsByUnitId = this.getters.contractsByUnitId;

        return _.chain(this.unitContext.getters.unitsById)
            .mapValues((unit, unitId) => {
                const contracts = _.chain(contractsByUnitId[unitId] ?? [])
                    .orderBy((contract: ContractData) => contract.start_date);

                return contracts
                    .filter((contract: ContractData) => contract.key_back_at === null)
                    .value()[0] ?? contracts
                    .orderBy((contract: ContractData) => contract.start_date, "desc")
                    .value()[0] ?? null;
            })
            .value();
    }

    get contractsByUnitId(): _.Dictionary<ContractData[]> {
        return _.chain(this.state.contracts)
            .groupBy(contract => contract.unit_id)
            .value();
    }

    get getContractLifeCycleStage(): (contract: ContractData) => ContractLifeCycleStage {
        return (contract: ContractData) => {
            if (contract.cancelled_at) return "cancelled";

            if (!contract.unit_id) return "unprocessed";

            if (dayjs().isBefore(contract.start_date, "day")) return "processed";

            if (!contract.key_pickup_at) return "arriving";

            if (dayjs().isBefore(contract.end_date, "day")) return "active";

            if (!contract.key_back_at) return "departing";

            if (!contract.outgoing_state_at) return "departed";

            return "end-of-life";
        }
    }

    get getContractStatus(): (contract: ContractData) => ContractStatus {
        return (contract: ContractData) => {
            if (contract.key_pickup_at && contract.key_back_at) {
                return "Previous occupants";
            } else if (contract.key_pickup_at) {
                return "Current occupants";
            }

            return "Next arrivals";
        }
    }
}