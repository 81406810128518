import {Actions, Context} from "vuex-smart-module";
import JobState from "@/store/modules/job/job-state";
import JobGetters from "@/store/modules/job/job-getters";
import JobMutations from "@/store/modules/job/job-mutations";
import esquireClient from "@/modules/esquire-client";
import {AxiosError} from "axios";
import JobData from "@/http/data/job-data";
import CreateJobRequest from "@/http/requests/create-job-request";
import db from "@/modules/vanity-db";
import {Store} from "vuex";
import {store} from "@/store";
import CancelJobCommand from "@/types/commands/cancel-job-command";
import CreateJobCommand from "@/types/commands/create-job-command";
import dayjs from "dayjs";
import PerformJobCommand from "@/types/commands/perform-job-command";
import UnitBlockadeData from "@/http/data/unit-blockade-data";
import _ from "lodash";

import root from "@/store/modules";
import unit from "@/store/modules/unit";
import user from "@/store/modules/user";
import {JobOverviewDisplayValue} from "@/types/strings";

export default class JobActions extends Actions<JobState, JobGetters, JobMutations, JobActions> {
    private rootContext!: Context<typeof root>
    private unitContext!: Context<typeof unit>;
    private userContext!: Context<typeof user>;

    $init(_store: Store<typeof store>): void {
        const rootContext = root.context(_store);

        this.rootContext = rootContext;
        this.unitContext = rootContext.modules.unit;
        this.userContext = rootContext.modules.user;

        super.$init(_store);
    }

    async attemptRestoreJobOverviewDateFrom(): Promise<void> {
        const dateFrom = await db.selected.get("job-overview-date-from");

        if (!dateFrom) return;

        this.mutations.setJobOverviewDateFrom(dateFrom.id as string);
    }

    async attemptRestoreJobOverviewDateUntil(): Promise<void> {
        const dateUntil = await db.selected.get("job-overview-date-until");

        if (!dateUntil) return;

        this.mutations.setJobOverviewDateUntil(dateUntil.id as string);
    }

    async attemptRestoreJobOverviewDisplay(): Promise<void> {
        const display = await db.selected.get("job-overview-display");

        if (!display) return;

        this.mutations.setJobOverviewDisplay(display.id as JobOverviewDisplayValue);
    }

    async attemptRestoreNewJobSelectedContract(): Promise<void> {
        const contract = await db.selected.get("new-job-contract");

        if (!contract) return;

        this.mutations.setSelectedNewJobContractId(contract.id as string);
    }

    async attemptRestoreNewJobSelectedUnitCheckTodoResult(): Promise<void> {
        const unitCheckTodoResult = await db.selected.get("new-job-unit-check-todo-result");

        if (!unitCheckTodoResult) return;

        this.mutations.setSelectedNewJobUnitCheckTodoResultId(unitCheckTodoResult.id as number);
    }

    async attemptRestoreSelectedNewJobType(): Promise<void> {
        const jobType = await db.selected.get("new-job-type");
        
        if (!jobType) return;

        this.mutations.setSelectedNewJobType(jobType.id as string);
    }

    async cancelJob({uuid, reason}: {
        uuid: string,
        reason: string
    }): Promise<void> {
        if (!this.userContext.state.session) return;

        const job = this.state.jobs.find(job => job.uuid === uuid);

        if (!job) return;

        const command = new CancelJobCommand(job, reason);

        await this.rootContext.actions.onCommand(command);

        this.mutations.cancelJob({
            uuid: uuid,
            cancelledBecause: reason,
            cancelledBy: this.userContext.state.session.username
        });
    }

    async clearSelectedNewJobContract(): Promise<void> {
        await db.selected.delete("new-job-contract");

        this.mutations.setSelectedNewJobContractId(null);
    }

    async clearSelectedNewJobUnitCheckTodoResult(): Promise<void> {
        await db.selected.delete("new-job-unit-check-todo-result");

        this.mutations.setSelectedNewJobUnitCheckTodoResultId(null);
    }

    async createJob(request: CreateJobRequest): Promise<void> {
        if (!this.userContext.state.session) return;

        const now = dayjs().utc(true).toISOString();

        const today = dayjs().utc(true).startOf("day").toISOString();

        const username = this.userContext.state.session.username;

        const job = new JobData({
                created_at: now,
                created_by: username,
                description: request.description,
                perform_date: today,
                type: request.type,
                contract_id: (!request.inventory_type || request.inventory_type === "other")
                && request.contract_id ? request.contract_id : undefined,
                unit_id: request.unit_id,
                unit_check_todo_result_id: request.unit_check_todo_result_id ?? undefined
            }
        );

        this.mutations.addJob(job);

        const command = new CreateJobCommand(request, (id: number) => {
            this.mutations.setJobId({uuid: job.uuid, id});
        });

        try {
            await this.rootContext.actions.onCommand(command);
        } catch (e) {
            this.mutations.removeJob(job);

            throw e;
        }
        
        if (request.block_room_from && request.block_room_until) {
            const unitBlockade = new UnitBlockadeData({
                unit_id: request.unit_id,
                created_by: username,
                type: "blocked",
                description: `Handyman job: ${request.description}`,
                from: request.block_room_from,
                until: request.block_room_until
            })

            this.unitContext.mutations.setUnitBlockade(unitBlockade);
        }
    }

    async indexJobs(): Promise<void> {
        const url = "jobs";

        const response = await esquireClient.get(url).catch((e: AxiosError) => e.response);

        if (!response) return;

        if (response.status === 200) {
            const jobs = _.map(response.data, data => new JobData(data));

            this.getters.createOrSynchronize(jobs);
        }
    }

    async initialize(): Promise<void> {
        await this.actions.indexJobs();

        this.mutations.setIsInitialized(true);
    }

    async performJob(uuid: string): Promise<void> {
        if (!this.userContext.state.session) return;

        const job = this.state.jobs.find(job => job.uuid === uuid);

        if (!job) return;

        const command = new PerformJobCommand(job);

        await this.rootContext.actions.onCommand(command);

        this.mutations.performJob({
            uuid: uuid,
            performedBy: this.userContext.state.session.username
        });
    }

    async rehydrateStore(): Promise<void> {
        await this.actions.attemptRestoreJobOverviewDateFrom();
        await this.actions.attemptRestoreJobOverviewDateUntil();
        await this.actions.attemptRestoreJobOverviewDisplay();
        await this.actions.attemptRestoreNewJobSelectedContract();
        await this.actions.attemptRestoreSelectedNewJobType();
        await this.actions.attemptRestoreNewJobSelectedUnitCheckTodoResult();
    }

    async selectJobOverviewDateFrom(value: null|string): Promise<void> {
        if (value) {
            db.selected.put({
                id: value
            }, "job-overview-date-from");
        } else {
            db.selected.delete("job-overview-date-from");
        }

        this.mutations.setJobOverviewDateFrom(value);
    }

    async selectJobOverviewDateUntil(value: null|string): Promise<void> {
        if (value) {
            db.selected.put({
                id: value
            }, "job-overview-date-until");
        } else {
            db.selected.delete("job-overview-date-until");
        }

        this.mutations.setJobOverviewDateUntil(value);
    }

    async selectJobOverviewDisplay(value: JobOverviewDisplayValue): Promise<void> {
        db.selected.put({
            id: value
        }, "job-overview-display");

        this.mutations.setJobOverviewDisplay(value);
    }

    async selectNewJobContract(contractId: string): Promise<void> {
        await db.selected.put({
            id: contractId
        }, "new-job-contract");

        await this.mutations.setSelectedNewJobContractId(contractId);
    }

    async selectNewJobType(type: string): Promise<void> {
        db.selected.put({
            id: type
        }, "new-job-type");

        this.mutations.setSelectedNewJobType(type);
    }

    async selectNewJobUnitCheckTodoResult(unitCheckTodoResultId: number): Promise<void> {
        await db.selected.put({
            id: unitCheckTodoResultId
        }, "new-job-unit-check-todo-result");

        await this.mutations.setSelectedNewJobUnitCheckTodoResultId(unitCheckTodoResultId);
    }
}