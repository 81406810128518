import {Actions, Context} from "vuex-smart-module";
import UserState from "@/store/modules/user/user-state";
import UserGetters from "@/store/modules/user/user-getters";
import UserMutations from "@/store/modules/user/user-mutations";
import esquireClient from "@/modules/esquire-client";
import CreateTokenRequest from "@/http/requests/create-token-request";
import {AxiosError} from "axios";
import SessionData from "@/http/data/session-data";
import ErrorData from "@/http/data/error-data";
import db from "@/modules/vanity-db";
import root from "@/store/modules";
import {Store} from "vuex";
import {store} from "@/store";

export default class UserActions extends Actions<UserState, UserGetters, UserMutations, UserActions> {
    declare rootContext: Context<typeof root>;

    $init(_store: Store<typeof store>): void {
        this.rootContext = root.context(_store);

        super.$init(_store);
    }

    async initialize(): Promise<void> {
        const session = await db.sessions.get("current");

        if (session) {
            this.mutations.setSession(session);

            esquireClient.defaults.headers.common["Authorization"] = "Bearer " + session.token;
        }

        this.mutations.setIsInitialized(true);
    }

    async login(createTokenRequest: CreateTokenRequest): Promise<void> {
        this.mutations.setRequestInProgress(true);

        if (this.state.loginError) this.mutations.setLoginError(null);

        const url = "tokens";

        const response = await esquireClient
            .post(url, createTokenRequest)
            .catch((e: AxiosError) => e.response);

        if (!response) {
            this.mutations.setLoginError({
                errors: {},
                message: "Could not get a response from the server. Please check your internet connection."
            });

            this.mutations.setRequestInProgress(false);

            return;
        }

        if (response.status === 200) {
            const session = new SessionData(response.data);
            this.mutations.setSession(session);
            await db.sessions.put(session, "current");
            esquireClient.defaults.headers.common["Authorization"] = "Bearer " + session.token;

            this.mutations.setIsInitialized(true);
        } else if (response.status === 400 || response.status === 422) {
            const error = response.data as ErrorData;
            this.mutations.setLoginError(error);
        } else {
            this.mutations.setLoginError({
                errors: {},
                message: "An unexpected error has occurred."
            });
        }

        this.mutations.setRequestInProgress(false);
    }

    async logout(): Promise<void> {
        this.mutations.setSession(null);

        await db.sessions.clear();

        const modules = this.rootContext.modules;

        modules.building.mutations.setIsInitialized(false);
        modules.contract.mutations.setIsInitialized(false);
        modules.job.mutations.setIsInitialized(false);
        modules.unit.mutations.setIsInitialized(false);
        modules.unitCheck.mutations.setIsInitialized(false);
        modules.user.mutations.setIsInitialized(false);
    }
}