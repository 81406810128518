import ContractClientData, {IContractClientData} from "@/http/data/contract-client-data";
import ContractData, {IContractData} from "@/http/data/contract-data";
import HttpData from "@/http/data/http-data";
import _ from "lodash";

export interface IReservationData {
    id: string;
    channel_id: string;
    contact: IContractClientData;
    contracts: IContractData[];
}

export default class ReservationData extends HttpData {
    public id: string;
    public source_id: string;
    public client: ContractClientData;
    public contracts: ContractData[];

    constructor(data: IReservationData) {
        super();
        this.id = data.id;
        this.source_id = data.channel_id;
        this.client = new ContractClientData(data.contact);
        this.contracts = _.map(data.contracts, data => new ContractData(data))
    }
}