import HttpData from "@/http/data/http-data";

export interface IContractClientData {
    first_name: string;
    last_name: string;
    type: string;
    company_name?: string;
    company_type?: string;
    company_vat?: string;
    address: string;
    zipcode: string;
    city: string;
    country: string;
    country_name?: string;
}

export default class ContractClientData extends HttpData {
    public first_name: string;
    public last_name: string;
    public type: string;
    public company_name: null|string;
    public company_type: null|string;
    public company_vat: null|string;
    public address: string;
    public zipcode: string;
    public city: string;
    public country: string;
    public country_name: null|string;

    constructor(data: IContractClientData) {
        super();

        this.first_name = data.first_name ;
        this.last_name = data.last_name;
        this.type = data.type;
        this.company_name = data.company_name ?? null;
        this.company_type = data.company_type ?? null;
        this.company_vat = data.company_vat ?? null;
        this.address = data.address;
        this.zipcode = data.zipcode;
        this.city = data.city;
        this.country = data.country;
        this.country_name = data.country_name ?? null;
    }
}