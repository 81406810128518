import ContractData from "@/http/data/contract-data";
import HttpData from "@/http/data/http-data";

export interface IJobData {
    id?: number;
    contract?: ContractData;
    type: string;
    description: string;
    perform_date: string;
    performed_at?: string;
    performed_by?: string;
    created_at: string;
    created_by: string;
    unit_id?: string;
    unit_check_todo_result_id?: number;
    building_id?: string;
    contract_id?: string;
    checked_at?: string;
    checked_by?: string;
    cancelled_at?: string;
    cancelled_by?: string;
    cancelled_because?: string;
    requires_empty_room?: boolean;
}

export default class JobData extends HttpData {
    public id: number|null;
    public building_id: null|string;
    public cancelled_at: null|string;
    public cancelled_because: null|string;
    public cancelled_by: null|string;
    public checked_at: null|string;
    public checked_by: null|string;
    public contract_id: null|string;
    public created_at: string;
    public created_by: string;
    public description: string;
    public perform_date: string;
    public performed_at: null|string;
    public performed_by: null|string;
    public requires_empty_room: boolean;
    public type: string;
    public unit_check_todo_result_id: null|number;
    public unit_id: null|string;

    private _shouldIndex: boolean;

    constructor(data: IJobData) {
        super();

        this._shouldIndex = true;

        this.id = data.id ?? null;
        this.created_at = data.created_at;
        this.created_by = data.created_by;
        this.description = data.description;
        this.perform_date = data.perform_date;
        this.type = data.type;

        this.building_id = data.building_id ?? null;
        this.cancelled_at = data.cancelled_at ?? null;
        this.cancelled_because = data.cancelled_because ?? null;
        this.cancelled_by = data.cancelled_by ?? null;
        this.checked_at = data.checked_at ?? null;
        this.checked_by = data.checked_by ?? null;
        this.contract_id = data.contract_id ?? null;
        this.performed_at = data.performed_at ?? null;
        this.performed_by = data.performed_by ?? null;
        this.requires_empty_room = data.requires_empty_room ?? false;
        this.unit_check_todo_result_id = data.unit_check_todo_result_id ?? null;
        this.unit_id = data.unit_id ?? null;
    }

    public equals(job: JobData): boolean {
        return this.id === job.id
            && this.uuid === job.uuid
            && this.building_id === job.building_id
            && this.cancelled_at === job.cancelled_at
            && this.cancelled_because === job.cancelled_because
            && this.cancelled_by === job.cancelled_by
            && this.checked_at === job.checked_at
            && this.checked_by === job.checked_by
            && this.contract_id === job.contract_id
            && this.created_at === job.created_at
            && this.created_by === job.created_by
            && this.description === job.description
            && this.perform_date === job.perform_date
            && this.performed_at === job.performed_at
            && this.performed_by === job.performed_by
            && this.requires_empty_room === job.requires_empty_room
            && this.type === job.type
            && this.unit_check_todo_result_id === job.unit_check_todo_result_id
            && this.unit_id === job.unit_id;
    }

    public hideFromIndex(): void {
        this._shouldIndex = false;
    }

    public shouldIndex(): boolean {
        return this._shouldIndex;
    }
}