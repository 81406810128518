import {Mutations} from "vuex-smart-module";
import BuildingState from "@/store/modules/building/building-state";
import BuildingData from "@/http/data/building-data";

export default class BuildingMutations extends Mutations<BuildingState> {
    setIsInitialized(value = true): void {
        this.state.isInitialized = value;
    }

    setBuildings(buildings: BuildingData[]): void {
        this.state.buildings = buildings;
    }

    setSelectedBuildingId(buildingId: string): void {
        this.state.selectedBuildingId = buildingId;
    }
}