import HttpData from "@/http/data/http-data";
import ContractClientData, {IContractClientData} from "@/http/data/contract-client-data";

export type CheckoutOptionName = "late_checkout_19h"|"late_checkout_13h"|"early_checkin_11h";

export interface IContractData {
    id?: string;
    reservation_id: string;
    start_date: string;
    end_date: string;
    unit_id: string;
    unit_type_id: string;
    amount_of_guests: string;
    has_inactive_device?: boolean;
    created_at: string;
    created_by: string;
    key_pickup_at?: string;
    key_pickup_by?: string;
    key_back_at?: string;
    key_back_by?: string;
    outgoing_state_at?: string;
    cancelled_at?: string;
    cancelled_because?: string;

    client?: IContractClientData;
    reception_modifiers?: CheckoutOptionName[];
}

export default class ContractData extends HttpData {
    public id: null|string;
    public reservation_id: null|string;
    public start_date: string;
    public end_date: string;
    public unit_id: string;
    public unit_type_id: string;
    public amount_of_guests: string;
    public created_at: string;
    public created_by: string;
    public key_pickup_at: null|string;
    public key_pickup_by: null|string;
    public key_back_at: null|string;
    public key_back_by: null|string;
    public outgoing_state_at: null|string;
    public cancelled_at: null|string;
    public cancelled_because: null|string;

    public client?: ContractClientData;
    public reception_modifiers?: CheckoutOptionName[];

    constructor(data: IContractData) {
        super();

        this.id = data.id ?? null;
        this.reservation_id = data.reservation_id ?? null;
        this.start_date = data.start_date;
        this.end_date = data.end_date;
        this.unit_id = data.unit_id;
        this.unit_type_id = data.unit_type_id;
        this.amount_of_guests = data.amount_of_guests;
        this.created_at = data.created_at;
        this.created_by = data.created_by;
        this.key_pickup_at = data.key_pickup_at ?? null;
        this.key_pickup_by = data.key_pickup_by ?? null;
        this.key_back_at = data.key_back_at ?? null;
        this.key_back_by = data.key_back_by ?? null;
        this.outgoing_state_at = data.outgoing_state_at ?? null;
        this.cancelled_at = data.cancelled_at ?? null;
        this.cancelled_because = data.cancelled_because ?? null;

        if (data.client) this.client = new ContractClientData(data.client);
        if (data.reception_modifiers) this.reception_modifiers = data.reception_modifiers;
    }
}