import {Mutations} from "vuex-smart-module";
import ContractState from "@/store/modules/contract/contract-state";
import ContractData from "@/http/data/contract-data";
import {ContractSearchFieldFilterValue} from "@/types/strings";
import ReservationData from "@/http/data/reservation-data";
import ContractInfoData from "@/http/data/contract-info-data";

export default class ContractMutations extends Mutations<ContractState> {
    setIsInitialized(value = true): void {
        this.state.isInitialized = value;
    }

    setContracts(contracts: ContractData[]): void {
        this.state.contracts = contracts;
    }

    setContractSearchFieldFilter(value: ContractSearchFieldFilterValue): void {
        this.state.contractSearchFieldFilter = value;
    }

    setSearchedReservations(reservations: ReservationData[]): void {
        this.state.searchedReservations = reservations;
    }

    setSelectedContractInfo(contractInfo: ContractInfoData|null): void {
        this.state.selectedContractInfo = contractInfo;
    }

    setSelectedContractInfoId(contractInfoId: string|null): void {
        this.state.selectedContractInfoId = contractInfoId;
    }
}