import JobData from "@/http/data/job-data";
import ErrorData from "@/http/data/error-data";
import {JobOverviewDisplayValue} from "@/types/strings";

export default class JobState {
    collapsedJobAccordionItem = "concierge";
    httpError: null|ErrorData = null;
    isInitialized = false;
    jobOverviewDateFrom: null|string = null;
    jobOverviewDateUntil: null|string = null;
    jobOverviewDisplay: JobOverviewDisplayValue = "all";
    jobs: JobData[] = [];
    showScheduledJobsOnly = false;
    showTodayJobsOnly = false;
    selectedNewJobContractId: null|string = null;
    selectedNewJobType = "";
    selectedNewJobUnitCheckTodoResultId: null|number = null;
}