import ICommand from "@/types/interfaces/i-command";
import {HttpResource} from "@/types/enums";
import esquireClient from "@/modules/esquire-client";
import {AxiosError} from "axios";
import CommandFailedError from "@/types/errors/command-failed-error";
import ErrorData from "@/http/data/error-data";

export default class ResetUnitCheckTodoResultCommand implements ICommand {
    constructor(private id: number) {
    }

    async execute(): Promise<void> {
        const url = `${HttpResource.UnitCheckTodoResults}/${this.id}/reset`;

        const response = await esquireClient.get(url).catch((e: AxiosError) => e.response);

        if (!response || response.status !== 200) {
            const alert = (response?.data as ErrorData).message;

            throw new CommandFailedError(`Finish Unit Check Todo ${this.id} failed.`, alert);
        }
    }
}