import HttpData from "@/http/data/http-data";

export interface IProductData {
    id?: number;
    category_id: number;
    category: string;
    name: string;
    description: string;
}

export default class ProductData extends HttpData {
    public id: null|number;
    public category_id: number;
    public category: string;
    public name: string;
    public description: string;

    constructor(data: IProductData) {
        super();

        this.id = data.id ?? null;
        this.category_id = data.category_id;
        this.category = data.category;
        this.name = data.name;
        this.description = data.description;
    }
}