<template>
    <router-view />
</template>

<script lang="ts">
import Vue from "@/extensions/Vue";
import {Options} from "vue-class-component";
import {ElMessage, ElMessageBox} from "element-plus";
import {rootContext} from "@/store";
import {ElMessageBoxOptions} from "element-plus/packages/message-box/src/message-box.type";

@Options({
    name: "App",
    components: {
        ElMessage
    }
})
export default class App extends Vue {
    mounted(): void {
        window.addEventListener("online", () => {
            rootContext.mutations.setIsOffline(false);
            rootContext.actions.runCommands();
        });

        window.addEventListener("offline", () => {
            rootContext.mutations.setIsOffline(true);
        });

        this.$events.on("message", (options: ElMessageBoxOptions|undefined) => {
            if (!options) return;

            this.$message(options);
        });

        this.$events.on("service-worker-updated", () => {
            ElMessageBox.confirm(
                "A refresh is required in order to update.",
                "New content available", {
                    confirmButtonText: "Refresh",
                    type: "info",
                    center: true,
                    showCancelButton: false,
                    closeOnClickModal: false,
                }
            )
                .then(() => {
                    navigator.serviceWorker.getRegistrations()
                        .then(registrations => {
                            for (const registration of registrations) {
                                registration.unregister();
                            }

                            window.location.reload();
                        });
                })
                .catch(() => console.warn("Update canceled."));
        });
    }
}
</script>

<style lang="scss">
@import "scss/app";
</style>
