import {Module} from "vuex-smart-module";
import ContractState from "@/store/modules/contract/contract-state";
import ContractGetters from "@/store/modules/contract/contract-getters";
import ContractMutations from "@/store/modules/contract/contract-mutations";
import ContractActions from "@/store/modules/contract/contract-actions";

export default new Module({
    state: ContractState,
    getters: ContractGetters,
    mutations: ContractMutations,
    actions: ContractActions
});